import React from 'react'
import styles from './style.module.css'

import { useModal } from 'src/lib/providers/modal'
import { TermsContent } from 'src/pages/Terms'

const TermsAndConditions = ({ title = 'Terms & Conditions' }) => {
    const { closeModal } = useModal()
    return (
        <>
            <div className={styles.closeIconBox}>
                <button
                    onClick={closeModal}
                    className={styles.closeButtonTerms}>
                    <img src="/close-icon.svg" alt="Close" />
                </button>
            </div>
            <div className={styles.termsWrapper}>
                <div className={styles.termsTitleImageBox}>
                    <div style={{ minWidth: '50%' }}>
                        <h3>{title}</h3>
                    </div>
                    <div className={styles.termsImageSection}></div>
                </div>
                <div className={styles.termsTextSection}>
                    <div style={{ height: '90px' }}></div>
                    <TermsContent/>

                    <div className={styles.termsTextGradient}></div>
                </div>
            </div>
        </>
    )
}

export default TermsAndConditions
