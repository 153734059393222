import { api } from "../api/client"
import { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";
import { usePrivateAPICall } from "./auctions";

export const useProfileUpdate = () => {
  const { setUser } = useAuth();
  const { call, isLoading, data, isDone, error } = usePrivateAPICall({
    method: 'patch',
    url: `/users/me/`
  });

  useEffect(() => {
    if (data != null) {
      setUser(data);
    }
  }, [data]);

  const profileUpdate = (data) => {
    call(data);
  };

  return { profileUpdate, isLoading, error };
}

const usePrivateList = (url, { limit=10, offset=10, ordering=""}) => {
  const [isLoading, setIsLoading] = useState(true);

  const { call, data, isDone, error } = usePrivateAPICall({
    method: 'get',
    url,
    params: { limit, offset, ordering }
  });

  useEffect(() => {
    setIsLoading(true);
    call();
  }, [limit, offset, ordering]);

  useEffect(() => {
    if (isDone) setIsLoading(false);
  }, [isDone]);

  return [{ items: data?.results || [], isLoading, error }];
}

export const useMyWatchList = ({ limit=10, offset=0, ordering=""}) => {
  return usePrivateList('/lots/likes/', { limit, offset, ordering });
}

export const useMyBidsList = ({ limit=10, offset=0, ordering=""}) => {
  return usePrivateList('/lots/bids/', { limit, offset, ordering });
}

export const useMyOwnedList = ({ limit=10, offset=0, ordering=""}) => {
  return usePrivateList('/lots/owned/', { limit, offset, ordering });
}

export const usePasswordUpdate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { access } = useAuth();

  async function passwordUpdate(data) {
    setError(null);
    setIsLoading(true);

    try {
      const response = await api.post(`/users/set_password/`, data, {
        headers: {
          'Authorization': `Bearer ${access}`
        }
      });
    } catch (error) {
      setError(error);
    }

    setIsLoading(false);
  }

  return { passwordUpdate, isLoading, error };
}

export const useTaxCountries = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);
  const { access } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        const d = await api.get(`/countries/`, { headers: { 'Authorization': `Bearer ${access}` } });;
        setData(d);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  return [{ items: data?.results || [], isLoading, error }];
};

// export const useLotsList = ({ auctionUUID, limit=10, offset=0, ordering=""}) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [data, setData] = useState(undefined);
//   const { access } = useAuth();

//   const request = (params) => {
//     const opts = {
//       params
//     };

//     if (access) opts.headers = {
//       'Authorization': `Bearer ${access}`
//     };

//     return api.get("/lots/", opts);
//   }

//   useEffect(() => {
//     const fetchData = async () => {
//       setError(null);
//       setIsLoading(true);

//       try {
//         const d = await request({ auction: auctionUUID, limit, offset, ordering });
//         setData(d);
//       } catch (error) {
//         setError(error);
//       }

//       setIsLoading(false);
//     };

//     fetchData();
//   }, [auctionUUID, limit, offset, ordering]);

//   return [{ items: data?.results || [], isLoading, error }];
// }

// export const useLot = (uuid, auction) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [data, setData] = useState(undefined);
//   const { access } = useAuth();

//   // const request = (uuid) => api.get(`/lots/${uuid}/`, { params: { auction } });

//   const request = (uuid) => {
//     const opts = {

//     };

//     if (access) opts.headers = {
//       'Authorization': `Bearer ${access}`
//     };

//     return api.get(`/lots/${uuid}/`, opts);
//   }

//   useEffect(() => {
//     const fetchData = async () => {
//       setError(null);
//       setIsLoading(true);

//       try {
//         const d = await request(uuid);
//         setData(d);
//         console.log("LOT RECEIVED", d);
//       } catch (error) {
//         setError(error);
//       }

//       setIsLoading(false);
//     };

//     fetchData();
//   }, [uuid]);

//   return [{ item: data, isLoading, error }];
// }
