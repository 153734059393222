import { Col, Container, Row } from 'react-bootstrap'
import InnerPageWrapper from 'src/components/inner-page-wrapper'

export default function Privacy() {
    return (
        <InnerPageWrapper>
            <Container>
                <Row>
                    <Col xs={12} lg={5} xl={4}>
                        <h1>
                            Privacy
                            <br />
                            Policy
                        </h1>
                    </Col>
                    <Col xs={12} lg={7} xl={8} className="mt-4 mt-lg-0 ">
                        <p>
                        Privacy Policy</p><p>
Last updated: October 06, 2024</p><p>
This Privacy Policy outlines our policies and procedures regarding the collection, use, and
disclosure of your information when you use the Service. It explains your privacy rights and
how the law protects you. We utilize your personal data to provide and enhance the
Service. By using the Service, you consent to the collection and use of information in
accordance with this Privacy Policy. This Privacy Policy has been created with the
assistance of the Privacy Policy Generator.
                        </p>

                        <h3>1. Interpretation and Definitions</h3>
                        <h4>1.1. Interpretation</h4>
                        <p>The words that begin with a capital letter have meanings defined under the following
conditions. The following definitions shall apply regardless of whether they appear in the
singular or plural.</p>
<h4>1.2. Definitions</h4>
                        <p>For the purposes of this Privacy Policy:</p><p>
"Account" refers to a unique account created for you to access our Service or parts of our
Service.</p><p>
"Affiliate" denotes an entity that controls, is controlled by, or is under common control with
a party, where "control" means ownership of 50% or more of the shares, equity interest, or
other securities entitled to vote for the election of directors or other managing authority.</p><p>
"Company" (referred to as "the Company", "We", "Us", “Memora” or "Our" in this
Agreement) refers to Etika Inc, 850 New Burton Road, Suite 201, Dover, DE 19904, USA.
"Cookies" are small files placed on your computer, mobile device, or any other device by a
website, containing details of your browsing history on that website, among other uses.</p><p>
"Country" refers to Delaware, United States.</p><p>
"Device" means any device that can access the Service, such as a computer, cellphone, or
digital tablet.</p><p>
"Personal Data" is any information that relates to an identified or identifiable individual.</p><p>
"Service" refers to the Website.</p><p>
"Service Provider" means any natural or legal person who processes the data on behalf of
the Company. It refers to third-party companies or individuals employed by the Company
to facilitate the Service, to provide the Service on behalf of the Company, to perform
services related to the Service, or to assist the Company in analyzing how the Service is
used.</p><p>
"Third-party Social Media Service" refers to any website or social network through which a
User can log in or create an account to use the Service.</p><p>
"Usage Data" refers to data collected automatically, either generated by the use of the
Service or from the Service infrastructure itself, such as the duration of a page visit.</p><p>
"Website" refers to Memora, accessible from http://www.memorahouse.io.</p><p>
"You" means the individual accessing or using the Service, or the company or other legal
entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
<h3>2. Collecting and Using Your Personal Data</h3>
<h4>2.1 Types of Data Collected</h4>
<p>While using our Service, we may request certain personally identifiable information from
you that can be used to contact or identify you. This information may include, but is not
limited to, your email address, first name and last name, phone number, address, phone
number, ZIP/postal code, country, your bank account details or payment card details or
bank transfer receipt, your date of birth, identification documents (including photo) proof
of address documents and the results of identification verification checks, details of your
past transactions (including any tax paid or tax withheld, and the source of your funds)
and/or shipments and usage data.</p>
<h4>2.2 Usage Data</h4>
<p>Usage Data is collected automatically when using the Service. This data may include
information such as your device's Internet Protocol address (e.g., IP address), browser
type, browser version, the pages of our Service that you visit, the time and date of your
visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
When you access the Service through a mobile device, we may collect certain information
automatically, including, but not limited to, the type of mobile device you use, your mobile
device unique ID, the IP address of your mobile device, your mobile operating system, the
type of mobile Internet browser you use, unique device identifiers, and other diagnostic
data. Additionally, we may collect information that your browser sends whenever you visit
our Service or access it through a mobile device.</p>
<h4>2.3 Information from Third-Party Social Media Services</h4>
<p>The Company allows you to create an account and log in to use the Service through ThirdParty Social Media Services such as Google. If you decide to register or grant us access to a
Third-Party Social Media Service, we may collect personal data already associated with
your Third-Party Social Media Service account, such as your name, email address,
activities, or contact list associated with that account. You may also choose to share
additional information with the Company through your Third-Party Social Media Service
account. By providing such information and personal data, you grant the Company
permission to use, share, and store it in a manner consistent with this Privacy Policy.</p>
<h4>2.4 Tracking Technologies and Cookies</h4>
<p>We employ Cookies and similar tracking technologies to monitor activity on our Service
and store certain information. Tracking technologies include beacons, tags, and scripts to
collect and analyze information and to improve our Service. The technologies we use may
include Cookies or Browser Cookies. A cookie is a small file placed on your device. You
can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
However, if you do not accept cookies, you may not be able to use some parts of our
Service. Unless you have adjusted your browser settings to refuse cookies, our Service
may use cookies. We also use web beacons in certain sections of our Service and our
emails to count users who visit those pages or open an email and for related website
statistics.</p><p>
Cookies can be categorized as either persistent or session cookies. Persistent cookies
remain on your device when you go offline, while session cookies are deleted as soon as
you close your web browser. We use both session and persistent cookies for various
purposes, including necessary cookies essential for providing services available through
the Website and to enable certain features, cookies for identifying if users have accepted
the use of cookies, and functionality cookies that help us remember choices you make
when using the Website, enhancing your personal experience.</p><p>
For further details regarding the cookies we use and your choices, please refer to our
Cookies Policy or the relevant section of this Privacy Policy.</p>
<h4>2.5 Use of Your Personal Data</h4>
<p>The Company may utilize your personal data for various purposes, including providing and
maintaining our Service, managing your Account, performing contracts, contacting you for
service updates, marketing communications, and managing your requests. Additionally,
we may analyze your data to identify trends, evaluate the effectiveness of our promotional
campaigns, and enhance our services.</p>
<h3>3. Sharing Your Personal Information</h3>
<p>Your personal information may be shared in several situations, such as with Service
Providers for analysis and communication, during business transfers related to mergers or
acquisitions, with affiliates while requiring them to honor this Privacy Policy, with business
partners to offer specific products or services, and with other users when you share
personal information in public areas. We may also disclose your personal information with
your consent or as legally required.</p>
<h3>4. Retention of Your Personal Data</h3>
<p>The Company will retain your Personal Data only for as long as necessary for the purposes
outlined in this Privacy Policy. We may retain your Personal Data to comply with legal
obligations, resolve disputes, or enforce our agreements and policies. Usage Data may
also be retained for internal analysis purposes, typically for a shorter period unless legally
required to retain it for longer.</p>
<h3>5. Transfer of Your Personal Data</h3>
<p>Your information, including Personal Data, may be processed at the Company’s operating
offices and any other locations where the parties involved in processing are located. This
may result in your data being transferred to computers outside of your jurisdiction. Your
submission of such information signifies your agreement to this transfer. We will take all
reasonable steps to ensure that your data is treated securely and in accordance with this
Privacy Policy, ensuring adequate controls are in place.</p>
<h3>6. Your Rights Regarding Personal Data</h3>
<p>Under GDPR, you have certain rights concerning your personal data, including the right to
access, correct, delete, or restrict the processing of your data. You also have the right to
data portability and to withdraw consent where consent is the legal basis for processing
your information.</p>
<h3>7. Delete Your Personal Data</h3>
<p>You have the right to delete or request assistance in deleting the Personal Data we have
collected about you. You may update, amend, or delete your information at any time by
signing in to your Account or contacting us to request access to, correct, or delete any
personal information.</p>
<h3>8. Disclosure of Your Personal Data</h3>
<p>Your Personal Data may be disclosed in various situations, including business
transactions involving mergers or asset sales, as required by law, or to protect and defend
the rights or property of the Company.</p>
<h3>9. Security of Your Personal Data</h3>
<p>While we prioritize the security of your Personal Data, it is important to note that no
method of transmission over the Internet or method of electronic storage is entirely
secure. We strive to utilize commercially acceptable means to protect your Personal Data
but cannot guarantee absolute security.</p>
<h3>10. Children's Privacy</h3>
<p>Our Service does not address anyone under the age of 13. We do not knowingly collect
personally identifiable information from individuals under 13. If you are a parent or
guardian and become aware that your child has provided us with Personal Data, please
contact us. If we learn that we have collected Personal Data from anyone under 13 without
verification of parental consent, we will take steps to remove that information from our
servers.</p>
<h3>11. Links to Other Websites</h3>
<p>Our Service may contain links to third-party websites not operated by us. If you click on a
third-party link, you will be directed to that third party’s site. We strongly advise you to
review the Privacy Policy of every site you visit, as we have no control over and assume no
responsibility for the content, privacy policies, or practices of any third-party sites or
services.</p>
<h3>12. Changes to This Privacy Policy</h3>
<p>We may update this Privacy Policy from time to time. We will notify you of any changes by
posting the new Privacy Policy on this page. You are advised to review this Privacy Policy
periodically for any changes. Changes to this Privacy Policy are effective when they are
posted on this page.</p>
<h3>13. Contact Us</h3>
<p>If you have any questions about this Privacy Policy, please contact us by email:
<a href="mailto:compliance@memorahouse.io"> compliance@memorahouse.io</a></p>

                    </Col>
                </Row>
            </Container>
        </InnerPageWrapper>
    )
}
