import { Container } from 'react-bootstrap'
import AboutWrapper from 'src/components/about-wrapper'
import InnerPageWrapper from 'src/components/inner-page-wrapper'

export default function AboutUs() {
    return (
        <InnerPageWrapper darkerBackground>
            <Container>
                <AboutWrapper>
                    <h1>About us</h1>
                    <div>
                    At Memora, we unlock a world of exclusivity: the hidden treasures of A-list celebrities, available to those who dare to dream. Our platform brings you face-to-face with the stories behind rare, authentic items from celebrity closets, each piece carrying its own unique legacy. Every collectible is more than just an item; it’s a part of history, carefully preserved and linked to its iconic owner. With Memora, you gain access to an unparalleled experience, where ownership is not only a privilege but an opportunity to make a difference. As you step into this world, you’re also contributing to causes that matter, turning each acquisition into a powerful act of giving.
                    </div>
                    <h2>Mission</h2>
                    <div>
                    At Memora, we’re redefining the world of celebrity memorabilia. We bring high-value collectibles back into the market, transforming them into powerful forces for positive change. With innovative technology ensuring secure, transparent transactions and authenticating each piece’s legacy, we’re building a community where culture, philanthropy, and circularity meet. We don’t just make collecting cool — we make doing good cool. Ready to own a piece of history and make a difference?
                    </div>
                </AboutWrapper>
            </Container>
        </InnerPageWrapper>
    )
}
