import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import ProgressBar from './progress-bar'
import Button from 'src/components/button'
import {loadStripe} from '@stripe/stripe-js';
import { useAuth } from 'src/lib/providers/auth';
import { api } from 'src/lib/api/client';

const stripe_key = 'pk_test_51PoC2URvsp5EyFHI8J70hij8nymDSHeLJeqIILrzwSGMlUVDWAqKmYhy3r2TUL3HGCJkbftIKqPJvtZn50ZyDhfG00TVN0Sa5Z';

const VerifyButton = () => {
    const { access, refreshUser } = useAuth();
    const [isStripeLoaded, setIsStripeLoaded] = useState(false);
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        async function load() {
            const s = await loadStripe(stripe_key);

            setStripe(s);
            setIsStripeLoaded(true);
            console.log("loaded");
        }
        load();
    }, []);

    const handleClick = async (event) => {
        console.log("on click");
        // Block native event handling.
        event.preventDefault();
        setIsStripeLoaded(false);


        if (!stripe) {
          // Stripe.js hasn't loaded yet. Make sure to disable
          // the button until Stripe.js has loaded.
          return;
        }

        // Call your backend to create the VerificationSession.

        const session = await api.get('/kyc/session/', {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        });

        // console.log(">>>>>", response);
        // const response = await fetch('/kyc/session/', { method: 'POST' });
        // const session = await response.json();

        // console.log(">>>>>>>>>", session);

        // Show the verification modal.
        const { error } = await stripe.verifyIdentity(session.client_secret);

        setIsStripeLoaded(true);
        refreshUser();

        if (error) {
          console.log('[error]', error);
        } else {
          console.log('Verification submitted!');
        }
      };

    return <Button disabled={!isStripeLoaded} onClick={handleClick}>Next</Button>
}

const VerifyIdentity = () => {
    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerStage}>03</div>
                Identity verification
            </div>
            <div className={styles.identityContainer}>
                <div className={styles.identityBox}>
                    <div className={styles.identityBoxHeader}>
                        <h4 className={styles.titleVerification}>
                            Identity verification
                        </h4>
                        <p>
                        Your security and trust are our top priorities. To help protect your account and ensure a safe and seamless experience, we kindly ask you to complete the identity verification process.
                        </p>
                    </div>

                    <div className={styles.verificationButtonWrapperThird}>
                        <VerifyButton />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyIdentity
