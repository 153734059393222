import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import InnerPageWrapper from 'src/components/inner-page-wrapper'
import PathConstants from 'src/routes/pathConstants';

export const TermsContent = () => {
    return <>
        <p>Welcome to MEMORA. These Terms and Conditions set out the terms on which MEMORA offers
you access to and use of our sites, services, applications, platforms and tools. For information
regarding the processing of personal data, please see our <Link to={PathConstants.PRIVACY}>Privacy Policy</Link>.</p>
        <p>These Terms and Conditions in their entirety constitute a binding legal agreement between
Etika Inc. 850 New Burton Road, Suite 201, Dover, DE 19904, USA, trading as MEMORA
("<b>MEMORA</b>", “<b>We</b>”, “<b>Our</b>”) and the User, being the individual accessing or using the MEMORA
website or MEMORA’s services, or the company or other legal entity on behalf of which such
individual is accessing or using the website or the services, as applicable (“You”, “User”).</p>
        <p>These Terms & Conditions apply to and govern your use of the MEMORA website accessible at
<a href="https://memorahouse.io/">https://memorahouse.io/</a> (“<b>Site</b>” or “<b>Platform</b>”) and any of our digital auction or related
services or content (the Site and these services and content are together referred to herein as
the “<b>Services</b>”). </p>
<p>Please read these Terms carefully and do not use this Site or the Services if you do not agree
with these Terms. By accessing the Platform, creating a User Account (being a personal account
created in the conditions defined herein, via the Platform using your email address and other
personal identifiers and enabling you to access MEMORA’s full breadth of Services including the
participation in auctions, subject to identity verifications), and using the Services as defined
below and participating in auctions via the Platform, you agree to enter into a legal agreement
with MEMORA and to be bound by these Terms and Conditions. If you do not agree to be bound
by these Terms and Conditions, do not create a User Account or use the Services via the
Platform.</p>
<p>The Services include the organization and conducting of online auctions, in relation to a variety
of Assets (hereinafter defined), and any services necessary to ensure that such auctions are
organized, conducted, advertised and marketed and that the Services are provided in
compliance with MEMORA’s legal obligations.</p>
<p>By participating in an auction and by bidding on an item, you agree to be bound to any purchase
you may win. By placing a bid on an item available for auction via the MEMORA Platform, you
are making an irrevocable offer of purchase in relation to the item on which you have placed
your bid. By placing bids on items available for auction, you represent and warrant to MEMORA
that you are financially capable of making the payment to MEMORA of the sum you have bidded
plus any applicable fees and taxes.</p>
<p>The most recent version of these Terms and Conditions will always be accessible on the
Platform. MEMORA reserves the right to modify these Terms and Conditions at any time, and
shall notify you of any modifications via the email you will have provided upon creation of your
User Account. Continued use of the Platform after changes have been made to the Terms and
Conditions indicates your acceptance of the updated Terms and Conditions. </p>
<p>The Services are available only to individuals who can form legally binding contracts in
compliance with these Terms and Conditions. Without limiting the foregoing, our Services are
not available to minors under the age of 18, nor persons who are barred from receiving the
Services under the laws of the United States or other applicable jurisdictions. You may not use
the Services if you do not qualify to use or receive the Services for any reason. Your use of the
Services constitutes your representation that you do qualify to use or receive the Services.</p>
<p>These Terms and Conditions contain provisions that govern how claims you and MEMORA may
have against each other are resolved, including a requirement to arbitrate disputes. Unless you
opt out of arbitration as described below, you agree that:</p><p>
(a) Any claims you have against MEMORA must be resolved through binding arbitration on an
individual basis, not as part of a class or representative action or proceeding; and</p><p>
(b) You may seek relief (including monetary, injunctive, and declaratory relief) only on an
individual basis.</p><p>
You have the right to opt out of this arbitration requirement within thirty (30) days of creating
your User Account. To opt out, you must send a written notice to MEMORA at
<a href="mailto:info@memorahouse.io">info@memorahouse.io</a> , clearly stating your name, the email associated with your account, and
your intent to opt out of arbitration. If you do not opt out within the specified period, the
arbitration requirement will be binding. The ability to opt out will be presented prominently in
these Terms and Conditions and as part of your account creation process, ensuring clear and
informed consent</p>
<h3>1. REGISTRATION OF A USER ACCOUNT, ACCOUNT SECURITY AND OPERATION OF THE
PLATFORM</h3>
<p>As a condition for using or accessing certain features of the Platform and of participating in
certain Services, in particular participating in auctions and making offers for purchasing items,
you must first create a User Account through the Site.</p>
<p>Registration on the Site requires the creation of a User Account whereby you provide MEMORA
with certain personal information including your real legal name, phone number, email address,
credit card details (via a secure third party verification and authentication system), date of birth,
postal address and country of residence, and other requested information. Creation of a User
Account will require you to select a password and user I.D. (“User I.D.”)</p>
<p>You may not select or use it as a User I.D. a name: (a) of another person with the intent to
impersonate that person; (b) subject to any rights of a person other than you without
appropriate authorization; or (c) that is otherwise offensive, vulgar or obscene. Memora
reserves the right to review, modify, or bar the use of any User I.D it deems unfit for purpose, at
any time and for any reason.</p>
<p>We reserve the right to refuse registration of, or to cancel or suspend pending further
investigation, a User Account, should it come to our attention the User Account is fraudulent,
infringes on another person’s rights, is offensive or obscene, or is made by a person who is not
legally authorized to enter into the auctions organized by MEMORA such as a minor under the
age of 18, such right being in our sole discretion and not limited to the situations described in
this paragraph.</p>
<p>The Site and access to the full range of MEMORA’s Services is available only to those who meet
certain eligibility criteria and have successfully registered a User Account. For the avoidance of
doubt, Users may make requests to create an account, which are subject to MEMORA’s final
approval.</p>
<p>For the avoidance of doubt, in order to request to create an account, you must:</p>
<p>
    <ul>
        <li>Be a natural person of at least 18 years of age and able to enter into binding legal
        agreements for the purchase of goods;</li>
        <li>Provide your full legal name;</li>
        <li>Provide your residential address and country of residency;</li>
        <li>Provide a valid and active email address;</li>
        <li>Agree to MEMORA’s terms and conditions, privacy policy and user guidelines.</li>
    </ul>
</p>
<p>When you request to create your User Account you will be asked to provide further information
in order for MEMORA to undertake requisite compliance checks in relation to your identity. As
part of the registration process and creation of a User Account, you will be asked to upload a
copy of a valid photo ID such as a passport, national identity card or driver’s license,
demonstrating that you are a natural person and that your name matches that provided in your
User Account application. This will be internally verified by Stripe, subject to its Privacy Policy.
This third party conducting the KYC checks, with whom MEMORA is not affiliated, is STRIPE and
its terms and conditions can be accessed here :<a href="https://stripe.com/legal/consumer">https://stripe.com/legal/consumer</a></p>
<p>You will be asked to create a password, which will be required for you to access and use the Site
and the Services, as well as to electronically sign and bind you to your transactions as wekk as to
review your completed transactions. MEMORA will never ask you to share your password in
writing or over the phone with any of its employees. It must be kept secret and is confidential.
You are solely responsible for maintaining the security of your User Account and password.</p><p>
You may not disclose your password to any third party and you are solely responsible for any use
of, or action taken under, your User Account and password. If your password is compromised,
you must change your password and immediately notify MEMORA of any unauthorized use or
other security breach. MEMORA reserves the right to continue any Auction involving a
compromised User Account as if such compromised User Account had never bid on such
Auction. MEMORA will not be liable for any loss or damage arising from your failure to take all
reasonable and appropriate steps to rectify the situation as soon as you have knowledge of or
suspect a breach of security of your User Account. .</p>
<h3>2. MEMORA AUCTIONS</h3>
<p>Once you have registered as a Useron the Site and this registration has been approved by
MEMORA following its validation checks, you will be able to access all of MEMORA’s Services via
the Platform, and in particular to place bids on items listed in the online auctions organized by
MEMORA.</p><p>
MEMORA organizes auctions, that is a sale of specified goods in which the goods are sold to the
highest bidder. Such auctions are conducted online via MEMORA’s Platform. MEMORA does not
own or purport to be the owner of any of the goods displayed for sale at auction via its Site. The
goods are the property of the listed owner until the auction process determines the highest
bidder, who becomes the new owner of the relevant listed goods subject to the payment of the
price they have bidded.</p><p>
the Site offers the following way to a User to bid on auctions for Assets: by bidding through an
online auction (the "Auction") on the Site that is offered by a charitable organization or third
party (collectively, the "Seller"). As used herein, a "Asset" is tangible Asset, including without
limitation artwork, apparel, food, fashion, and sports and music memorabilia and its digital
twins. As used herein, the term "Lot" refers to an Asset offered through an Auction.</p><p>
We offer the Services as a technology web application to allow Sellers to conduct Auctions. For
more information on such promotional offers, see the Auctions Section below. Payment,
processing and transaction fees will be added on top of a winning bid. All purchases are final and
non-refundable. We are constantly evolving and improving the Services and may add or change
features and offerings in the future. We may also modify, suspend or discontinue all or some of
the Services at any time without notice or liability. Eligible Users are free to bid on an Auction.
Use of the Services is intended for individuals who are at least eighteen (18) years of age, unless
otherwise indicated. Additional restrictions may apply to any auction and will be disclosed in the
specific rules for such auction. If you are not at least eighteen (18) years old, please do not
access, visit or use the Site or Services.</p><p>
The Services provide a platform for Sellers to offer Lots for Auction, to Users, and for the User
with the highest bid at the close of the Auction to purchase the Lot. The ending time for each
Auction is stated on the bidding page for each such Lot. Bids will be accepted from the opening
of the Auction until the end time only. MEMORA reserves the right, in its sole discretion and
without notice, to extend, terminate or modify the Auction in the event of technical difficulties
altering the proper functioning of the Site or the Auction.</p><p>
When an Auction goes live, Users are free to place bids under their User Account on the Lot until
the Auction closes. As bids are placed, MEMORA reserves the right to authorize your credit card
for any bid amount placed by your User Account. Each time you are outbid MEMORA will
highlight your name in the bid section to notify you that your lot was outbid.</p><p>
By bidding on any Lot, you agree to purchase the Asset at the amount you have bid together
with costs associated therewith. You agree that you should bid on a Lot and your bid is the
winning bid, that you are bound to pay MEMORA the winning bid amount for the Lot. You
further agree that all bids placed under your User Account are deemed to be placed by the User
or with the User's authorization and consent, and User is solely responsible for all activity under
his or her User Account.</p><p>
At the close of an Auction, MEMORA will review all bids for the Lot and if you are the highest
bidder (the "Winning Bidder"), MEMORA will inform you by the email registered to your User
Account. The highest bid is referred to herein as the "Hammer Price." By bidding in an Auction
you acknowledge and agree that within five (5) business days following the close of an Auction,
the Winning Bidder's credit card or bank account will be charged the Hammer Price, plus the
following costs and fees (collectively, the “Costs”): sales tax, use tax, DST, VAT and any other
applicable taxes, buyer's premium, plus shipping costs, and insurance costs while in transit
(collectively, the Hammer Price plus the Costs are the "Total Purchase Price"). The Winning
Bidder is also responsible for any applicable customs and/or duties. All transactions on MEMORA
are displayed and charged in U.S. dollars (USD), unless specifically stated otherwise.</p><p>
Following collection in full of the Total Purchase Price for donated items, MEMORA will send
100% of Net Proceeds to the Foundation or DAF, who will then grant the funds, less fees, to the
charitable organization indicated on the lot page. Net Proceeds is defined as Hammer Price.
Some of the Lots offered on this Site may be subject to a minimum price at which the Lot will be
sold (the "Reserve Price"). MEMORA is not obligated to sell the Lot unless the Reserve Price is
met and an Auction may be withdrawn if there is no bid equal to or above the Reserve Price.
Some Lots may be subject to a live auction (a "Live Bid"). If the Lot will be part of a Live Bid, the
Lot can also be auctioned off at an event sponsored by MEMORA. The bidders in Live Bid and
the bidders at the event must follow the same rules to participate in the Auction.
Owners of Asset being sold on the Site are prohibited from bidding on or offering to buy their
own Asset , and shall not instruct, authorize or permit any other person to bid for or purchase
such Asset on their behalf or to otherwise take any action that may manipulate the sale process
in any way, unless you agree to purchase the Asset at the price you bid.</p>
<h3>3. PURCHASE OF ASSETS</h3>
<p>You understand that each Lot will include a description of specific rules and restrictions
governing the fulfillment of the Lot, including but not limited to rules related to appearance
times, time constraints, event dates or locations, or other particulars related to the Lot. You
further understand that the Lot may not be able to be fulfilled if you do not adhere to these
rules. If a Lot is not fulfilled because the rules governing such Lot are not observed by you
and/or your permitted attendees, you will forfeit the Lot as well as the Total Purchase Price for
the Lot. MEMORA is not responsible for any cancellation, delay, diversions, or omission of any
third party with respect to a Lot. Lost or stolen tickets, travel vouchers or certificates for the
Assets will not be replaced once in your possession.</p><p>
Damaged Asset. In the event Asset shipped to you is damaged in transit, you must notify
MEMORA within two (2) business days of your receipt of the Asset. You must retain, photograph
and document all packing materials and all contents of the package(s), including the Asset, in
order to make a claim for repair, replacement or refund. MEMORA will work with the Winning
Bidder and/or the Asset donor, if applicable, to pursue insurance claims, if appropriate, and/or
obtain the repair or replacement of the Asset. If repair or replacement are not available or
possible given the nature of the Asset, MEMORA will issue to the Winning Bidder a partial or full
refund of the winning bid amount, applying all available insurance proceeds if appropriate.</p><p>
MEMORA reserves the right to delay delivery of an Asset and/or documents and information
related to an Asset to the Winning Bidder if MEMORA, at its sole discretion, believes the
Winning Bidder may have violated these Terms. MEMORA will conduct its investigation as fast
as possible and notify the Winning Bidder of the results thereof. Notwithstanding any other
provision herein, if MEMORA determines at its sole discretion that there has been a material
violation by Winning Bidder, MEMORA may refuse to return all, or any portion of payments or
charges made in connection with the purchase of a Lot or Lots.</p>
<h3>4. LIMITATIONS OF GUARANTEES.</h3>
<p>MEMORA is a digital auction platform for bidding on Lots for good. Information pertaining to the
descriptions, conditions, title, authenticity, Donor's ability and authority to donate an Asset, and
all other information related to the Lot, is provided by the Seller. While MEMORA will endeavor
to use commercially reasonable efforts to confirm the authenticity of the Asset it receives and
posts for auction via the Services, MEMORA makes no claims, representations or warranties
regarding the authenticity of any Asset sold on the Site.</p><p>
Accordingly, to the extent permitted by applicable law, we exclude all express or implied
warranties, terms and conditions including, but not limited to, implied warranties of
merchantability, fitness for a particular purpose, and non infringement. In addition, to the extent
permitted by applicable law, MEMORA (including our affiliates, officers, directors, shareholders,
employees, contractors, licensors, licensees, agents, and representatives (collectively, “Covered
Entities”)) is not liable, and you agree not to hold the Covered Entities responsible, for any
damages or losses (including, but not limited to, loss of money, goodwill or reputation, profits,
or other intangible losses or any special, indirect, or consequential damages) resulting directly
or indirectly from the items posted on the Site.</p>
<h3>5. REFUSAL OF TRANSACTION</h3>
<p>MEMORA reserves the right, at its sole discretion, to withdraw any Lot before the end of an
Auction, halt any sale during its progress, and/or remove, screen or edit any materials or content
on the Site. MEMORA may refuse to process a transaction for any reason or refuse service to
anyone at any time at its sole discretion. Neither MEMORA nor any of its affiliates will be liable
to you, or a third party for any reason by MEMORA's withdrawal of any Lot prior to the end of
the auction period. In the event of failure by you to complete a sale for any reason, including
MEMORA's decision to refuse to process the transaction, MEMORA shall have the right to sell
the Asset to another User. In the event of identical bids in an Auction, the earliest bid will be the
Winning Bidder. In all cases, MEMORA's determination of a Winning Bidder will be final.</p>
<h3>6. GENERAL PROVISIONS.</h3>
<p>A. EMAIL COMMUNICATION.</p>
<p>Email is MEMORA’s primary means of communicating with Users, of promoting any upcoming
auctions, and of providing information to those who have placed bids and participated in
auctions. All email communications from MEMORA will be sent to the email address you have
provided upon creating your User Account.</p>
<p>By creating a User Account, using the Services and participating in auctions, you agree to receive
any and all correspondence from MEMORA via email. You agree to monitor your email for
communications from MEMORA requiring your attention or response, and in particular where
you have placed bids on certain items. In the event you have placed the winning bid on an item,
you will be notified of this via email and required to make prompt payment. Failure on your part
to respond in an appropriate timeframe may result in your bid being forfeited and going to the
second highest bidder.</p>
<p>B. PUBLICITY GRANT</p>
<p>If you are the Winning Bidder, MEMORA may request permission for MEMORA, the Seller or
Charitable Recipient, and their designees or assigns to use your name, address (city and state
only), photograph, voice and/or other likeness for advertising, trade and promotional purposes
without further compensation, in all media now known or hereafter discovered, worldwide in
perpetuity,, unless prohibited by law. You have the right to refuse such requests or to authorize
the use of certain information only. If permission is granted, you will in due course and as
appropriate sign all requisite waivers and releases as requested by MEMORA, the Seller or
Charitable Recipient, to evidence such permission.</p>
<p>C. MONITORING.</p>
<p>MEMORA has the right, but not the obligation, to monitor any activity and content associated
with the Services. MEMORA is under no obligation to do so and assumes no responsibility or
liability arising from the content of any such transactions or for any error, defamation, libel,
slander, omission, falsehood, obscenity, pornography, profanity, danger, illegality or inaccuracy
contained in any information transmitted to any such locations on the Site. MEMORA may
investigate any reported violations of the Site policies or complaints and take any action that it
deems appropriate (which may include, but is not limited to, issuing warnings, suspending or
terminating Services, denying access and/or removing any materials on the Site, including
listings and bids). MEMORA may also investigate, at its sole discretion, the use of any credit card
by a User and to take such action as MEMORA deems appropriate, including without limitation,
contacting the individual using such card, canceling bids or an offer to purchase placed by such
user, and/or deactivating a MEMORA User account. MEMORA will fully cooperate with any law
enforcement authorities or court order requesting or directing MEMORA to disclose the identity
of anyone posting any such information or materials. MEMORA may also disclose such
information if it has a good faith belief that such disclosure is reasonably necessary to protect
the rights, Asset, or personal safety of MEMORA, each MEMORA User, Sellers or the public.</p>
<p>D. GOVERNING LAW/DISPUTES</p>
<p>These Terms shall be governed by and construed and enforced in accordance with the laws of
the State of Delaware, United States of America. Any legal suit, action or proceeding arising out
of, or related to, these Terms shall be instituted exclusively in the federal or state courts located
in Delaware, USA. You waive any and all objections to the exercise of jurisdiction over you by
such courts and to venue in such courts.</p>
<p>E. THIRD PARTY LINKS.</p>
<p>Our Platform may contain links to other sites and resources provided by third parties, these links
are provided for your convenience only. We have no control over the contents of those sites or
resources and accept no responsibility for them or for any loss or damage that may arise from
your use of them. If you decide to access any of the third-party websites linked to this Website,
you do so entirely at your own risk and subject to the terms and conditions of use for such
websites.</p>
<p>F. LIMITATION OF LIABILITY </p>
<p>WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE, VALIDITY,
ACCURACY, RELIABILITY OF, OR OTHERWISE RESPECTING THE CONTENT OR MERCHANDISE
AVAILABLE ON THE WEBSITE. DOWNLOADING OR OTHERWISE OBTAINING ANY CONTENT
THROUGH THE WEBSITE IS DONE AT YOUR OWN RISK. THE MERCHANDISE AND THE CONTENT
OF THE WEBSITE IS PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS, WITHOUT
WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT POSSIBLE
UNDER APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
PURPOSE, AND NONINFRINGEMENT.</p>
<p>MEMORA AND ITS PARTNERS, OFFICERS, EMPLOYEES, AGENTS, AND CONTRACTORS WILL NOT
BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING OUT OF OR RELATING TO THE USE OF OR
THE INABILITY TO USE THE WEBSITE OR THE SERVICES, THE WEBSITE’S CONTENT, OR LINKS,
INCLUDING BUT NOT LIMITED TO DAMAGES CAUSED BY OR RELATED TO ERRORS, OMISSIONS,
INTERRUPTIONS, DEFECTS, DELAYS IN OPERATION OR TRANSMISSION, OR COMPUTER VIRUSES,
NOR SHALL THERE BE ANY LIABILITY FOR ANY ACTS, OMISSIONS OR CONDUCT OF ANY USER OR
OTHER THIRD PARTY.</p>
<p>MEMORA AND ITS PARTNERS, OFFICERS, EMPLOYEES, AGENTS, AND CONTRACTORS WILL NOT
BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES,
EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
<p>G. INDEMNITY</p>
<p>You agree to indemnify and hold harmless MEMORA and its partners, officers, employees,
agents and contractors from any and all claims, damages, losses, costs (including without
limitation reasonable attorneys’ fees) or other expenses that arise directly or indirectly out of or
from: (i) your breach of any provision of these Terms; (ii) your activities in connection with the
Website, our Services or the Merchandise; or (iii) unsolicited information or content you provide
to us through the Website.</p>
<p>H. INTERNATIONAL BIDDERS</p>
<p>The Website is controlled, operated and administered by MEMORA from our offices within the
United States of America and is not intended to subject us to the laws or jurisdiction of any
country or territory other than that of the United States. WE DO NOT REPRESENT OR WARRANT
THAT THE WEBSITES OR ANY PART THEREOF ARE APPROPRIATE OR AVAILABLE FOR USE IN ANY
PARTICULAR JURISDICTION OTHER THAN THE UNITED STATES. Those who choose to access the
Website do so on their own initiative and at their own risk, and are responsible for complying
with all local statutes, orders, regulations, rules, and other laws.</p>
<p>I. TERMINATION.</p>
<p>You may terminate this Agreement at any time by discontinuing your use of the Site. You do not
need to inform MEMORA when you stop using the Site. MEMORA may, at any time and for any
reason, limit your use of this Site at its sole discretion. MEMORA, at its sole discretion and for
any reason, may terminate your right to participate in an Auction or to terminate your access to
the Site without notice.</p>
<p>J. NOTICES.</p>
<p>To be effective, notices to MEMORA relating to this Agreement, the Site, the Services or any
transaction conducted on or through the Site or the Services must be given in writing and must
be sent to MEMORA by registered letter, receipted commercial courier, or electronically
receipted facsimile transmission (acknowledged in like manner by the intended recipient) at the
following addresses: Etika Inc, 850 New Burton Road, Suite 201, Dover, Kent County, Delaware
19904. MEMORA may change its address for notices by posting the new address on the Site.</p>
<p>Copies of such notices may be sent to MEMORA at : <a href="mailto:legal@memorahouse.io">legal@memorahouse.io</a></p>
<p>K. SEVERABILITY; NON-WAIVER.</p>
<p>If any provision of these Terms shall be deemed unlawful, void, or for any reason unenforceable
by a court of competent jurisdiction, you and MEMORA nevertheless agree that the court
should endeavor to give effect to your and MEMORA' intentions as reflected in the provision,
and the other provisions of these terms remain in full force and effect.</p>
<p>Except as specifically provided herein, this document constitutes the entire agreement between
you and MEMORA relating to the subject matter described herein. The provisions of these Terms
may be amended, revised or modified by MEMORA at any time. These terms do not modify,
revise, or amend the terms of any other agreements you may have with MEMORA.</p>
    </>;
}

export default function Terms() {
    return (
        <InnerPageWrapper>
            <Container>
                <Row>
                    <Col xs={12} lg={5} xl={4}>
                        <h1>Terms & Conditions</h1>
                    </Col>
                    <Col xs={12} lg={7} xl={8} className="mt-4 mt-lg-0 ">
                        <TermsContent />
                    </Col>
                </Row>
            </Container>
        </InnerPageWrapper>
    )
}
