import { api } from "../api/client"
import { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";
import { useNavigate } from "react-router";
import { useModal } from "../providers/modal";
import { MODAL } from "src/components/layouts/Layout";

export const usePublicAPICall = ({
  method,
  url,
  headers,
  params,
  data
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(undefined);
  const [responseReceived, setResponseReceived] = useState(false);
  const [error, setError] = useState(undefined);

  const call = async () => {
    console.log("use public call", url);
    setIsLoading(true);
    setResponseReceived(false);
    setError(null);

    try {
      const response = await api({ method, url, headers, params, data });
      console.log(`API PUBLIC ${url}`, response);
      setResponseData(response);
      setIsLoading(false);
      setResponseReceived(true);
    } catch(e) {
      setError(e);
      setIsLoading(false);
    }
  }

  return { call, data: responseData, isLoading, error, isDone: !isLoading && (responseReceived || error) };
}

export const usePublicAPI = ({
  method,
  url,
  headers,
  params,
  data
}) => {
  const { call, data: responseData, isLoading, error } = usePublicAPICall({
    method,
    url,
    headers,
    params,
    data
  });

  useEffect(() => {
    call();
  }, []);

  return { data: responseData, isLoading, error };
}

export const usePrivateAPICall = ({
  method,
  url,
  headers,
  params,
  data
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseReceived, setResponseReceived] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { access, refresh, setAccess, logout } = useAuth();

  const requestHeaders = (access) => Object.assign({}, headers, {
    'Authorization': `Bearer ${access}`
  });

  const call = async (providedData) => {
    setIsLoading(true);
    setResponseData(null);
    setError(null);
    setResponseReceived(false);
    console.log("run call");
    try {
      console.log(`API authorized call ${url}`);
      const response = await api({ method, url, headers: requestHeaders(access), params, data: providedData || data });
      setResponseReceived(true);
      setResponseData(response);
      setIsLoading(false);
    } catch(e) {
      if (e.response.status === 403) {
        try {
          console.log(`try to refresh access token ${url}`);
          const { access: newAccess } = await api.post("/auth/jwt/refresh", { refresh });
          setAccess(newAccess);

          try {
            console.log(`try to repeat request ${url}`);
            const response = await api({ method, url, headers: requestHeaders(newAccess), params, data: providedData || data });
            setResponseReceived(true);
            setResponseData(response);
            setIsLoading(false);
            console.log(`request repeated successfully ${url}`);
          } catch(er2) {
            console.log(`request 2 failed ${url}`, er2);
            setIsLoading(false);
            setError(er2);
          }
        } catch(er) {
          console.log(`failed to refresh access token ${url}`);
          setIsLoading(false);
          logout();
          navigate("/?modal=login");
          // openModal(MODAL.LOGIN);

        }

      } else {
        console.log(`request failed ${url}`);
        setIsLoading(false);
        setError(e);
      }
    }
  }

  // console.log("CALL", isLoading, error, responseReceived);

  return { call, data: responseData, isLoading, isDone: !isLoading && (error || responseReceived), error };
}

export const usePriorityAPI = ({
  method,
  url,
  headers,
  params,
  data
}) => {
  const { access } = useAuth();
  const [isPrivate] = useState(access != null);
  const {
    call: callPublic,
    data: dataPublic,
    isLoading: isLoadingPublic,
    isDone: isDonePublic,
    error: errorPublic
  } = usePublicAPICall({ method, url, headers, params, data });
  const {
    call: callPrivate,
    data: dataPrivate,
    isLoading: isLoadingPrivate,
    isDone: isDonePrivate,
    error: errorPrivate
  } = usePrivateAPICall({ method, url, headers, params, data });

  useEffect(() => {
    if (isPrivate) callPrivate();
    else callPublic();
  }, [url]); // FIXME: other deps

  if (isPrivate) return { data: dataPrivate, error: errorPrivate, isLoading: isLoadingPrivate, isDone: isDonePrivate };
  else return { data: dataPublic, error: errorPublic, isLoading: isLoadingPublic, isDone: isDonePublic };
}

export const useAuctionsList = ({ limit=10, offset=0, ordering=""} = {}) => {
  const { data, isLoading, error } = usePublicAPI({
    method: 'get',
    url: '/auctions/',
    params: { limit, offset, ordering }
  });

  return [{ items: data?.results || [], isLoading, error }];
}

export const useAuction = (uuid) => {
  const { data, isLoading, error } = usePublicAPI({
    method: 'get',
    url: `/auctions/${uuid}/`
  });

  return [{ item: data, isLoading, error }];
}