import styles from './style.module.css'
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import SpeedyBids from '../speedy-bids'
import Checkbox from '../checkbox'
import { usePlaceBid } from 'src/lib/hooks/bids'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export const toUSD = (amount) => amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const BidForm = ({ placeBid, setIsPlaced, lot, bids }) => {
    // const [bidValue, setBidValue] = useState()
    // const [{ placeBid, isLoading }] = usePlaceBid(lot.uuid);

    const isSpeedyBidsEnabled = true

    // const onPlaceBid = () => {
    //     setStep(1)
    // }
    // const increaseBid = value => {
    //     setBidValue(prev => {
    //         if (prev) {
    //             return prev + value
    //         } else {
    //             return value
    //         }
    //     })
    // }

    const lastBid = bids.length ? bids[0] : { amount: 0 };
    const BidSchema = Yup.object().shape({
        amount: Yup.number().min(lastBid.amount + 1).required('Required'),
        isAnonymous: Yup.boolean()
    });

    const formik = useFormik({
        initialValues: {
            amount: '',
            isAnonymous: false,
        },
        validationSchema: BidSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        validateOnMount: true,
        onSubmit: async values => {
            await placeBid(values);
            setIsPlaced(true);
            formik.setFieldValue("amount", '')
        }
    });

    const isEnableButton = formik.errors.amount == null;
    const recommendedBid = lastBid.amount + 1;

    return (
        <div className={styles.container}>
            <div className={styles.scrollable}>
                <div className={styles.wrapper}>
                    <div>
                        <input
                            className={styles.input}
                            type="number"
                            placeholder={toUSD(recommendedBid)}
                            name="amount"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                            autoFocus={true}
                        />
                    </div>
                    <div className={styles.bidDescription}>
                        Enter your bid of {toUSD(recommendedBid)} or more
                    </div>
                </div>

                <div
                    className={`${styles.actions} ${isEnableButton ? '' : styles.disabled}`}>
                    <div className={styles.plusButtons}>
                        <button
                            className={styles.buttonPlus}
                            onClick={() => {
                                formik.setFieldValue("amount", lastBid.amount + 100)

                            }}>
                            + {toUSD(100)}
                        </button>
                        <button
                            className={styles.buttonPlus}
                            onClick={() => {
                                formik.setFieldValue("amount", lastBid.amount + 500)

                            }}>
                            + {toUSD(500)}
                        </button>
                        <button
                            className={styles.buttonPlus}
                            onClick={() => {
                                formik.setFieldValue("amount", lastBid.amount + 1000)
                            }}>
                            + {toUSD(1000)}
                        </button>
                    </div>
                    <div className="text-center">
                        <div
                            className={`${styles.button}`}
                            onClick={formik.handleSubmit}>
                            Place bid
                        </div>
                        <Checkbox
                            label={'Keep my bid anonymous'}
                            disabled={!isEnableButton}
                            name="isAnonymous"
                            value={formik.values.isAnonymous}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                {isSpeedyBidsEnabled && <SpeedyBids />}
            </div>
        </div>
    )
}

export default BidForm
