import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { usePasswordUpdate } from 'src/lib/hooks/user'
import { FormGroup } from 'react-bootstrap'
import { useToast } from 'src/lib/providers/toast'

const PasswordUpdateSchema = Yup.object().shape({
    'current_password': Yup.string().required("Current password is required"),
    'new_password': Yup.string().required("New password is required"),
});

const PasswordPage = () => {
    const { passwordUpdate, error, isLoading } = usePasswordUpdate();
    const { showToast } = useToast();

    const formik = useFormik({
        initialValues: {
            'current_password': '',
            'new_password': ''
        },
        validationSchema: PasswordUpdateSchema,
        // validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            console.log("Run submit");
            console.log(values);
            passwordUpdate(values);
            // updateProfile(values);
        }
    });

    useEffect(() => {
        if (!isLoading && error) showToast(error.message);
    }, [isLoading, error]);

    return (
        <>
            <div className={styles.passwordUserWrapper}>
                <img className={styles.backIconMobile} />
                <div className={styles.InformationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Password</h4>
                    </div>
                    <div className={styles.formContainer}>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            UPDATE PASSWORD
                        </p>
                        <Container className={styles.informationUserContainer}>
                            <Form style={{ minHeight: 'auto' }} onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="password"
                                                placeholder="Old password"
                                                className={styles.userPageInput}
                                                name="current_password"
                                                value={formik.values.current_password || ''}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.current_password}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.current_password}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="password"
                                                placeholder="New password"
                                                className={styles.userPageInput}
                                                name="new_password"
                                                value={formik.values.new_password || ''}
                                                onChange={formik.handleChange}
                                                isInvalid={!!formik.errors.new_password}
                                            />
                                            <Form.Control.Feedback type="invalid">{formik.errors.new_password}</Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <div className={styles.buttonBox}>
                                    {/* FIXME: use toast */}
                                    {/* { error && <span style={{color:'red' }}>{error.message}</span> } */}
                                    <button
                                        type="submit"
                                        className={`${styles.btnUserPage} ${styles.active} ${styles.resizeButton}`}>
                                        Update password
                                    </button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordPage
