import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import metamaskIcon from 'src/assets/images/metam3.jpg'
import Button from 'src/components/button'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from 'src/lib/providers/auth'
import { useProfileUpdate, useTaxCountries } from 'src/lib/hooks/user'
import { FormGroup } from 'react-bootstrap'
import UploadFileButton from 'src/components/upload-file-button'
import { useRefreshUser } from 'src/lib/hooks/auth'
import { readFileBase64 } from 'src/components/profile-edit-tabs/information'
import { useToast } from 'src/lib/providers/toast'

const InformationEditSchema = Yup.object().shape({
    username: Yup.string().nullable().max(150, '150 characters or fewer'), // string, optional, unique, max 150
    first_name: Yup.string()
        .required('First name is required')
        .max(150, '150 characters or fewer'), // string, required, max 150
    last_name: Yup.string()
        .required('Last name is required')
        .max(150, '150 characters or fewer'), // string, required, max 150
    phone: Yup.string().nullable().max(24, '24 characters or fewer'), // string, optional, max 24
    tax_country: Yup.string().required('Tax country is required'), // string, required, dictionary

    delivery_address: Yup.string()
        .required('Delivery address is required')
        .max(150, '500 characters or fewer'), // string, required, max 150
    delivery_city: Yup.string()
        .required('Delivery city is required')
        .max(150, '150 characters or fewer'),
    delivery_country: Yup.string().required('Delivery country is required'), // string, required, dictionary
    delivery_zip: Yup.string().required('Delivery ZIP is required')
});

const VerifyProfileInfo = () => {
    const [hasPhoto, setHasPhoto] = useState(false);
    const { showToast } = useToast();
    const photoUrl = metamaskIcon
    const handlePhotoUpload = () => {
        setHasPhoto(true)
    }

    const { user } = useAuth();
    const [{ items: countries }] = useTaxCountries()
    const { profileUpdate, error: updateError, isLoading } = useProfileUpdate()
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        if (!isLoading && updateError) showToast(updateError.message);
    }, [isLoading, updateError]);

    const formikInfo = useFormik({
        initialValues: {
            username: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            tax_country: user.tax_country,

            delivery_address: user.delivery_address,
            delivery_city: user.delivery_city,
            delivery_country: user.delivery_country,
            delivery_zip: user.delivery_zip
        },
        validationSchema: InformationEditSchema,
        // validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            console.log('Run submit')
            console.log(values)
            profileUpdate(values)
        },
    });

    const selectedCountryName = countries.find(
        c => c.slug == formikInfo.values['tax_country'],
    )?.name;

    const selectedDeliveryCountryName = countries.find(
        c => c.slug == formikInfo.values['delivery_country'],
    )?.name;

    const handleCountrySelect = country => {
        formikInfo.setFieldValue('tax_country', country)
    };

    const handleDeliveryCountrySelect = country => {
        formikInfo.setFieldValue('delivery_country', country)
    };

    const uploadAvatar = async (file) => {
        const file64 = await readFileBase64(file);
        profileUpdate({ avatar_file: file64 });
    };

    return (
        <>
            <div className={styles.verificationHeader}>
                <div className={styles.headerStage}>01</div>
                Profile information
            </div>
            <div className={styles.informationWrapper}>
                <div className={styles.verificationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Profile information</h4>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            USER INFORMATION
                        </p>
                        <div className={styles.uploadPhotoBox}>
                            <div
                                className={styles.photo}
                                style={{
                                    backgroundColor: user.avatar
                                        ? 'transparent'
                                        : 'rgba(212, 207, 199, 1)',
                                    backgroundImage: user.avatar
                                        ? (`url(${user.avatar})`)
                                        : 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>
                                {!user.avatar && <p>Upload photo</p> }
                                {!user.avatar && (
                                    <div className={styles.photoIconMobile}>
                                        <img
                                            src="/emptyIcon.svg"
                                            alt="Upload Photo"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.uploadButtonBox}>
                                <UploadFileButton
                                    text={'Upload new photo'}
                                    accept="image/*"
                                    onChange={e => {
                                        const file = e.target.files.length && e.target.files[0];

                                        if (file) uploadAvatar(file);
                                    }}
                                />
                                {/* FIXME: button differs from profile edit*/}
                                <Button
                                    // className={`${styles.btnUserPage} ${styles.btnDelPhoto}`}
                                    style={{ marginTop: '16px' }}
                                    variant="neutral-dark"
                                    onClick={() => profileUpdate({ avatar_file: null })}>
                                    Delete photo
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.formContainer}>
                        <Container className={styles.bootstrapContainer}>
                            <Form onSubmit={formikInfo.handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            className={styles.userPageInput}
                                            name="email"
                                            value={user.email}
                                            disabled={true}
                                        />
                                    </Col>

                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Username"
                                                className={styles.userPageInput}
                                                name="username"
                                                value={
                                                    formikInfo.values
                                                        .username || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors.username
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.username}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                    <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="First name"
                                                className={styles.userPageInput}
                                                name="first_name"
                                                value={
                                                    formikInfo.values
                                                        .first_name || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors
                                                        .first_name
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.first_name}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                    <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Last name"
                                                className={styles.userPageInput}
                                                name="last_name"
                                                value={
                                                    formikInfo.values
                                                        .last_name || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors
                                                        .last_name
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.last_name}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Phone number"
                                                className={styles.userPageInput}
                                                name="phone"
                                                value={
                                                    formikInfo.values.phone ||
                                                    ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors.phone
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.phone}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>{' '}
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="hidden"
                                                placeholder=""
                                                className={styles.userPageInput}
                                                name="countryChecker"
                                                value={
                                                    formikInfo.values[
                                                        'tax_country'
                                                    ] || ''
                                                }
                                                // onChange={formikInfo.handleChange}
                                                isInvalid={
                                                    !!formikInfo.errors[
                                                        'tax_country'
                                                    ]
                                                }
                                            />
                                            <Dropdown
                                                onSelect={handleCountrySelect}>
                                                <Dropdown.Toggle
                                                    className={`${styles.userPageInput} ${styles.dropdownToggle} w-100 d-flex justify-content-between align-items-center`}
                                                    id="tax-residency-country-dropdown">
                                                    <span
                                                        className={`text-start ${!formikInfo.values['tax_country'] ? styles.inactiveText : ''}`}>
                                                        {selectedCountryName ||
                                                            'Tax residency country'}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="w-100"
                                                    style={{
                                                        maxHeight: '192px',
                                                        overflowY: 'auto',
                                                    }}>
                                                    {countries.map(country => (
                                                        <Dropdown.Item
                                                            key={country.slug}
                                                            eventKey={
                                                                country.slug
                                                            }>
                                                            {country.name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    formikInfo.errors[
                                                        'tax_country'
                                                    ]
                                                }
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <p className={styles.deliveryText}>
                                    DELIVERY ADDRESS
                                </p>
                                <Row>
                                    <Col>
                                        {/* <Form.Control
                                            type="text"
                                            placeholder="Address"
                                            className={styles.userPageInput}
                                            name="address"
                                        /> */}

                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Address"
                                                className={styles.userPageInput}
                                                name="delivery_address"
                                                value={
                                                    formikInfo.values
                                                        .delivery_address || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors.delivery_address
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.delivery_address}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/* <Form.Control
                                            type="text"
                                            placeholder="City"
                                            className={styles.userPageInput}
                                            name="city"
                                        /> */}
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="City"
                                                className={styles.userPageInput}
                                                name="delivery_city"
                                                value={
                                                    formikInfo.values
                                                        .delivery_city || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors.delivery_city
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.delivery_city}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        {/* <Form.Control
                                            type="text"
                                            placeholder="Country"
                                            className={styles.userPageInput}
                                            name="country"
                                        /> */}
                                        <FormGroup>
                                            <Form.Control
                                                type="hidden"
                                                placeholder=""
                                                className={styles.userPageInput}
                                                name="countryChecker2"
                                                value={
                                                    formikInfo.values[
                                                        'delivery_country'
                                                    ] || ''
                                                }
                                                // onChange={formikInfo.handleChange}
                                                isInvalid={
                                                    !!formikInfo.errors[
                                                        'delivery_country'
                                                    ]
                                                }
                                            />
                                            <Dropdown
                                                onSelect={handleDeliveryCountrySelect}>
                                                <Dropdown.Toggle
                                                    className={`${styles.userPageInput} ${styles.dropdownToggle} w-100 d-flex justify-content-between align-items-center`}
                                                    id="delivery-country-dropdown">
                                                    <span
                                                        className={`text-start ${!formikInfo.values['delivery_country'] ? styles.inactiveText : ''}`}>
                                                        {selectedDeliveryCountryName ||
                                                            'Delivery country'}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="w-100"
                                                    style={{
                                                        maxHeight: '192px',
                                                        overflowY: 'auto',
                                                    }}>
                                                    {countries.map(country => (
                                                        <Dropdown.Item
                                                            key={country.slug}
                                                            eventKey={
                                                                country.slug
                                                            }>
                                                            {country.name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    formikInfo.errors[
                                                        'delivery_country'
                                                    ]
                                                }
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="ZIP code"
                                                className={styles.userPageInput}
                                                name="delivery_zip"
                                                value={
                                                    formikInfo.values
                                                        .delivery_zip || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors.delivery_zip
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.delivery_zip}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </div>
                    <div className={styles.buttonBox}>
                        {/* FIXME: use toast */}
                        {updateError && (
                            <span style={{ color: 'red' }}>
                                {updateError.message}
                            </span>
                        )}
                        <Button onClick={() => formikInfo.handleSubmit()}>Next</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyProfileInfo
