import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import metamaskIcon from 'src/assets/images/metam3.jpg'
import BackIcon from 'src/assets/images/backIcon'
import Button from 'src/components/button'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAuth } from 'src/lib/providers/auth'
import { useProfileUpdate, useTaxCountries } from 'src/lib/hooks/user'
import { FormGroup } from 'react-bootstrap'
import UploadFileButton from 'src/components/upload-file-button'
import { useToast } from 'src/lib/providers/toast'

const InformationEditSchema = Yup.object().shape({
    username: Yup.string().nullable().max(150, '150 characters or fewer'), // string, optional, unique, max 150
    first_name: Yup.string()
        .required('First name is required')
        .max(150, '150 characters or fewer'), // string, required, max 150
    last_name: Yup.string()
        .required('Last name is required')
        .max(150, '150 characters or fewer'), // string, required, max 150
    phone: Yup.string().nullable().max(24, '24 characters or fewer'), // string, optional, max 24
    tax_country: Yup.string().required('Tax country is required'), // string, required, dictionary
});

export const readFileBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
}

const ProfileInformationPage = () => {
    const { user } = useAuth()
    const [{ items: countries }] = useTaxCountries()
    const { profileUpdate, error: updateError, isLoading } = useProfileUpdate();
    const [avatar, setAvatar] = useState(null);
    const { showToast } = useToast();

    const formikInfo = useFormik({
        initialValues: {
            username: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            tax_country: user.tax_country,
        },
        validationSchema: InformationEditSchema,
        // validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            console.log('Run submit')
            console.log(values)
            profileUpdate(values)
            // updateProfile(values);
        },
    });

    useEffect(() => {
        if (!isLoading && updateError) showToast(updateError.message);
    }, [isLoading, updateError]);

    const selectedCountryName = countries.find(
        c => c.slug == formikInfo.values['tax_country'],
    )?.name

    // const countries = [
    //     'Russia',
    //     'China',
    //     'Canada',
    //     'Germany',
    //     'France',
    //     'Japan',
    //     'United Kingdom',
    //     'United States',
    //     'Russia',
    //     'China',
    //     'Canada',
    //     'Germany',
    //     'France',
    //     'Japan',
    //     'United Kingdom',
    //     'United States',
    // ]
    // const photoUrl = metamaskIcon
    const handlePhotoUpload = () => {
        // setHasPhoto(true)
    }

    const handleCountrySelect = country => {
        formikInfo.setFieldValue('tax_country', country)
        // setSelectedCountry(country)
    }

    const uploadAvatar = async (file) => {
        const file64 = await readFileBase64(file);
        profileUpdate({ avatar_file: file64 });
        // console.log(file64);
    }

    return (
        <>
            <div className={styles.informationUserWrapper}>
                <img className={styles.backIconMobile} />
                <div className={styles.InformationItem}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}>
                        <h4>Profile information</h4>

                        <div className={styles.uploadPhotoBox}>
                            <div
                                className={styles.photo}
                                style={{
                                    backgroundColor: user.avatar
                                        ? 'transparent'
                                        : 'rgba(212, 207, 199, 1)',
                                    backgroundImage: user.avatar
                                        ? (`url(${user.avatar})`)
                                        : 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}>
                                {!user.avatar && <p>Upload photo</p> }
                                {!user.avatar && (
                                    <div className={styles.photoIconMobile}>
                                        <img
                                            src="/emptyIcon.svg"
                                            alt="Upload Photo"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={styles.uploadButtonBox}>
                                <UploadFileButton
                                    text={'Upload new photo'}
                                    accept="image/*"
                                    onChange={e => {
                                        const file = e.target.files.length && e.target.files[0];

                                        if (file) uploadAvatar(file);
                                    }}
                                />
                                <button
                                    className={`${styles.btnUserPage} ${styles.btnDelPhoto}`}
                                    onClick={() => profileUpdate({ avatar_file: null })}>
                                    Delete photo
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.formContainer}>
                        <p
                            style={{
                                fontFamily: 'GT Pressura, monospace',
                                marginBottom: '16px',
                                fontSize: '12px',
                                lineHeight: '12px',
                            }}>
                            USER INFORMATION
                        </p>
                        <Container className={styles.informationUserContainer}>
                            <Form
                                style={{ minHeight: 'auto' }}
                                onSubmit={formikInfo.handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            className={styles.userPageInput}
                                            name="email"
                                            value={user.email}
                                            disabled={true}
                                        />
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Username"
                                                className={styles.userPageInput}
                                                name="username"
                                                value={
                                                    formikInfo.values
                                                        .username || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors.username
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.username}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="First name"
                                                className={styles.userPageInput}
                                                name="first_name"
                                                value={
                                                    formikInfo.values
                                                        .first_name || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors
                                                        .first_name
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.first_name}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Last name"
                                                className={styles.userPageInput}
                                                name="last_name"
                                                value={
                                                    formikInfo.values
                                                        .last_name || ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors
                                                        .last_name
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.last_name}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Phone number"
                                                className={styles.userPageInput}
                                                name="phone"
                                                value={
                                                    formikInfo.values.phone ||
                                                    ''
                                                }
                                                onChange={
                                                    formikInfo.handleChange
                                                }
                                                isInvalid={
                                                    !!formikInfo.errors.phone
                                                }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formikInfo.errors.phone}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>{' '}
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Form.Control
                                                type="hidden"
                                                placeholder=""
                                                className={styles.userPageInput}
                                                name="countryChecker"
                                                value={
                                                    formikInfo.values[
                                                        'tax_country'
                                                    ] || ''
                                                }
                                                // onChange={formikInfo.handleChange}
                                                isInvalid={
                                                    !!formikInfo.errors[
                                                        'tax_country'
                                                    ]
                                                }
                                            />
                                            <Dropdown
                                                onSelect={handleCountrySelect}>
                                                <Dropdown.Toggle
                                                    className={`${styles.userPageInput} ${styles.dropdownToggle} w-100 d-flex justify-content-between align-items-center`}
                                                    id="tax-residency-country-dropdown">
                                                    <span
                                                        className={`text-start ${!formikInfo.values['tax_country'] ? styles.inactiveText : ''}`}>
                                                        {selectedCountryName ||
                                                            'Tax residency country'}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="w-100"
                                                    style={{
                                                        maxHeight: '192px',
                                                        overflowY: 'auto',
                                                    }}>
                                                    {countries.map(country => (
                                                        <Dropdown.Item
                                                            key={country.slug}
                                                            eventKey={
                                                                country.slug
                                                            }>
                                                            {country.name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    formikInfo.errors[
                                                        'tax_country'
                                                    ]
                                                }
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className={styles.buttonBox}>
                                    {/* FIXME: use toast
                                    {updateError && (
                                        <span style={{ color: 'red' }}>
                                            {updateError.message}
                                        </span>
                                    )} */}
                                    <Button type="submit">
                                        Update profile
                                    </Button>
                                </div>
                            </Form>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileInformationPage
