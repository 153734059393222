import { useEffect, useState } from 'react'
import Spinner from 'src/components/spinner'
import { fetchData } from 'src/data/api'
import AuctionCard from 'src/components/auction/auction-card'
import { useAuctionsList } from 'src/lib/hooks/auctions'

const AuctionList = ({ type = 'current' }) => {
    const [{ items: auctions, isLoading: loading, error }] = useAuctionsList()

    if (loading) {
        // temp solution
        return (
            <div
                style={{
                    width: '100%',

                    height: '700px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Spinner />
            </div>
        )
    }

    return (
        <div className="position-relative">
            {auctions.map(auction => {
                return <AuctionCard key={auction.uuid} auction={auction} />
            })}
        </div>
    )
}

export default AuctionList
