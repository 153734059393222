import { Navigate } from "react-router";
import VerificationLayout from "src/components/verification-user";
import VerifyProfileInfo from "src/components/verification-user/first-step-verification";
import VerifyTerms from "src/components/verification-user/fourth-step-verification";
import VerifySubmitted from "src/components/verification-user/reviewing-verification";
import VerifyBank from "src/components/verification-user/second-step-verification";
import VerifyIdentity from "src/components/verification-user/third-step-verification";
import { useAuth } from "src/lib/providers/auth";
import PathConstants from "src/routes/pathConstants";

const steps = [
  {
    id: 'step1',
    component: <VerifyProfileInfo />,
    icon: '01',
    title: 'Profile information',
  },
  {
    id: 'step2',
    component: <VerifyIdentity />,
    icon: '02',
    title: 'Identity verification',
  },
  // {
  //   id: 'step3',
  //   component: <VerifyBank />,
  //   icon: '03',
  //   title: 'Bank verification',
  // },
  {
    id: 'step4',
    component: <VerifyTerms />,
    icon: '04',
    title: 'Accept terms & policies',
  },
  {
    id: 'finished',
    component: <VerifySubmitted />,
    icon: '05',
  }
]

export default function VerificationPage() {
  const { user } = useAuth();

  const verificationStatus = user['verification_status'];
  const verificationStep = user['verification_step'];
  // const verificationStep = 'step1';

  if (verificationStatus == 'verified') return <Navigate to={PathConstants.PROFILE} />;

  const stepIndex = steps.map(s => s.id).indexOf(verificationStep);
  const currentStep = steps[stepIndex];

  return <VerificationLayout steps={steps} currentStep={currentStep} stepIndex={stepIndex}>
    {currentStep.component}
  </VerificationLayout>;
}