import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import styles from './style.module.css'
import Button from 'src/components/button'
import { useAuth } from 'src/lib/providers/auth'
import { avatarStub } from 'src/lib/shared/constants'
import { useNavigate } from 'react-router'
import PathConstants from 'src/routes/pathConstants'
import { useRefreshUser } from 'src/lib/hooks/auth'

const VStatusNotVerified = () => {
    const navigate = useNavigate();

    return <div className={styles.verifyText}>
        <h4
            style={{
                fontSize: '36px',
                lineHeight: '41px',
                marginBottom: '8px',
            }}>
            Not verified
        </h4>
        <p>Complete your profile to start the verification</p>
        <p>process and begin placing bids.</p>
        <Button
            variant="primary"
            style={{ marginTop: '16px' }}
            fullWidth={false}
            onClick={() => navigate(PathConstants.VERIFICATION)}
        >
            Start verification
        </Button>
    </div>;
}

const VStatusIncomplete = () => {
    const navigate = useNavigate();

    return <div className={styles.verifyText}>
        <h4
            style={{
                fontSize: '36px',
                lineHeight: '41px',
                marginBottom: '8px',
            }}>
            Not verified
        </h4>

        <p>Finish completing your profile so we can verify</p>
        <p>your account and enable bidding.</p>
        <Button
            variant="primary"
            style={{ marginTop: '16px' }}
            fullWidth={false}
            onClick={() => navigate(PathConstants.VERIFICATION)}
        >
            Continue verification
        </Button>
    </div>;
}

const VStatusProcessing = () => {
    return <div className={styles.verifyText}>
        <h4
            style={{
                fontSize: '36px',
                lineHeight: '41px',
                marginBottom: '8px',
            }}>
            Profile under review
        </h4>

        <p>Your profile is under review.</p>
        <p>Verification typically takes 24-48 hours.</p>

    </div>;
}

const VStatusFailed = () => {
    const navigate = useNavigate();

    return <div className={styles.verifyText}>
        <h4
            style={{
                fontSize: '36px',
                lineHeight: '41px',
                marginBottom: '8px',
            }}>
            Verification failed
        </h4>

        <p>We encountered an issue verifying your profile. Please review your information and start the</p>
        <p>verification process again. If the problem persists, contact our support team for assistance.</p>
        <Button
            variant="primary"
            style={{ marginTop: '16px' }}
            fullWidth={false}
            onClick={() => navigate(PathConstants.VERIFICATION)}
        >
            Start verification
        </Button>
    </div>;
}

const VStatus = ({ verificationStatus }) => {
    switch(verificationStatus) {
        case 'not_verified': return <VStatusNotVerified />;
        case 'incomplete': return <VStatusIncomplete />;
        case 'processing': return <VStatusProcessing />;
        case 'verified': return <></>;
        case 'failed': return <VStatusFailed />;
        case 'unknown': return <VStatusFailed />;
    }
}

const UserHero = () => {
    const { user } = useAuth()
    const { refreshUser } = useRefreshUser();
    const navigate = useNavigate()

    const name = user?.first_name || user?.username
    const greetings = name ? `, ${name}` : ''

    const verificationStatus = user['verification_status'];
    // const verificationStatus = 'not_verified';
    // const verificationStatus = 'incomplete';
    // const verificationStatus = 'processing';
    // const verificationStatus = 'verified';
    // const verificationStatus = 'failed';
    // console.log(verificationStatus);

    useEffect(() => {
        refreshUser()
    }, []);

    return (
        <div className={`${styles.heroUserSection}`}>
            <Container>
                <Row>
                    <Col>
                        <div className={`${styles.titleImageBox}`}>
                            <h1>Welcome {greetings}</h1>
                            <div
                                style={{
                                    width: '212px',
                                    height: '212px',
                                    borderRadius: '50%',
                                    backgroundImage: `url(${user?.avatar ? user?.avatar : avatarStub})`, //рабочий вариант, когда решится вопрос с корректной загрузкой картинок
                                    // backgroundImage: `url(https://i.pinimg.com/736x/3f/f1/20/3ff120824122616c4922797749f58fba.jpg)`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundColor: '#EDE9E3',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                }}>
                                { verificationStatus == 'verified' && <div className={`${styles.verified}`}></div> }
                                <div className={`${styles.imageButton}`}>
                                    <Button
                                        variant="neutral-light"
                                        fullWidth={false}
                                        onClick={() =>
                                            navigate(PathConstants.PROFILE_EDIT)
                                        }>
                                        Edit profile
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <VStatus verificationStatus={verificationStatus} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UserHero
