import { api } from "../api/client"
import { useState, useEffect } from "react";
import { useAuth } from "../providers/auth";
import { usePrivateAPICall, usePriorityAPI } from "./auctions";

export const useLotsList = ({ auctionUUID, limit=10, offset=0, ordering=""}) => {
  const { data, isLoading, error } = usePriorityAPI({
    method: 'get',
    url: '/lots/',
    params: { auction: auctionUUID, limit, offset, ordering }
  });

  return [{ items: data?.results || [], isLoading, error }];
}

export const useLot = (uuid) => {
  const { data, isLoading, isDone, error } = usePriorityAPI({
    method: 'get',
    url: `/lots/${uuid}/`
  });

  return [{ item: data, isLoading, isDone, error }];
}

export const useLotLike = (uuid) => {
  const { call, isLoading, isDone } = usePrivateAPICall({
    method: 'post',
    url: `/lots/${uuid}/like/`
  });

  return { call, isLoading, isDone };
}

export const useLotDisike = (uuid) => {
  const { call, isLoading, isDone } = usePrivateAPICall({
    method: 'post',
    url: `/lots/${uuid}/dislike/`
  });

  return { call, isLoading, isDone };
}