import React from 'react'
import styles from './style.module.css'
import CloseIcon from 'src/assets/images/closeIcon'
import Title from 'src/components/modal/modals-components/title'
import SocialIcons from 'src/components/modal/login/socialIcons'
import { useModal } from 'src/lib/providers/modal'
import { MODAL } from 'src/components/layouts/Layout'
import { t } from 'src/lib/i18n'
import Form from 'react-bootstrap/Form'
import formStyles from '../modals-components/form/style.module.css'
import { useGoogleLoginLink, useLogin } from 'src/lib/hooks/auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'src/lib/providers/auth'
// eslint-disable-next-line react/prop-types

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
})

const LoginModal = () => {
    const { closeModal, openModal } = useModal()
    const { login, error, isLoading } = useLogin()
    const { isLoggedIn } = useAuth()
    const [searchParams] = useSearchParams()
    const { googleURL } = useGoogleLoginLink();

    const redirectTo =
        searchParams.get('redirect_to') || window.location.pathname

    const failedAttemptError =
        error?.response?.status === 401 ? error.response.data.detail : ''

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: LoginSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => login(values),
    })

    if (isLoggedIn) return <Navigate to={redirectTo} />

    return (
        <div className={styles.loginWrapper}>
            <Form onSubmit={formik.handleSubmit}>
                <div className={styles.loginContent}>
                    <div className={styles.wrapperLogin}>
                        <Title
                            handleCloseModal={closeModal}
                            isEndPosition
                            title="Welcome">
                            <CloseIcon onClick={closeModal} />
                        </Title>
                        <Form.Control
                            type="text"
                            placeholder={t('Email')}
                            className={formStyles.modalInput}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            autoFocus={true}
                        />
                        {formik.errors.email}
                        <Form.Control
                            type="password"
                            placeholder={t('Password')}
                            className={formStyles.modalInput}
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.password}
                        <div className={styles.linkForgotBox}>
                            <Link
                                className={styles.link}
                                onClick={() =>
                                    openModal(MODAL.FORGOT_PASSWORD)
                                }>
                                Forgot password
                            </Link>
                        </div>
                    </div>

                    <div className={styles.modalFormBox}>
                        <div className={styles.loginBox}>
                            <button
                                type="submit"
                                className={styles.btnLogin}
                                disabled={isLoading}>
                                {isLoading ? t('Loading..') : t('Login')}
                            </button>
                            {failedAttemptError}
                            <Link
                                className={styles.link}
                                onClick={() => openModal(MODAL.REGISTER)}>
                                {t('Create account')}
                            </Link>
                        </div>
                        <div className={styles.footerText}>
                            OR CONTINUE WITH
                        </div>
                        <SocialIcons googleURL={googleURL} facebookURL={''} appleURL={''} />
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default LoginModal
