import './App.css'
import routes from 'src/routes'
import Layout from 'src/components/layouts/Layout'
import Page404 from 'src/pages/Page404'
import {
    createBrowserRouter,
    RouterProvider,
    useLocation,
    useSearchParams,
} from 'react-router-dom'
import { ModalProvider } from './lib/providers/modal'
import { useEffect } from 'react'
import { ToastProvider } from './lib/providers/toast'

function ScrollToTop() {
    const { pathname } = useLocation()
    // const [searchParams] = useSearchParams();
    // const modalParam = searchParams.get('modal');

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        })
    }, [pathname])

    return null
}

function App() {
    const router = createBrowserRouter([
        {
            element: (
                <ModalProvider>
                    <ToastProvider>
                        <ScrollToTop />
                        <Layout />
                    </ToastProvider>
                </ModalProvider>
            ),
            errorElement: <Page404 />,
            children: routes,
        },
    ])

    return <RouterProvider router={router} />
}

export default App
